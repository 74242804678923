import Vue from "vue";

export default {
    version: "1.3",
    BASE_URL: 'https://admin.debizz.ro/api/index.php/',

    set_token: function(newToken) {
        window.localStorage.setItem("admin-thebizz-token", newToken);
        Vue.http.headers.common["Token"] = newToken;
    },

    get_token: function() {
        return window.localStorage.getItem("admin-thebizz-token");
    },

    set_drepturi: function (drepturi) {
        window.localStorage.setItem('admin-thebizz-drepturi_user', JSON.stringify(drepturi));
    },

    get_drepturi: function () {
        var drepturi = window.localStorage.getItem('admin-thebizz-drepturi_user');
        return JSON.parse(drepturi);
    },
    set_user_type: function (user_type) {
        window.localStorage.setItem('admin-thebizz-user_type', user_type);
    },

    get_user_type: function () {
        return window.localStorage.getItem('admin-thebizz-user_type');
    },

    is_logged_in: function() {
        var token = this.get_token();
        return token !== "";
    },

    logout: function() {
        this.set_token('');
        this.set_drepturi('');
    },

    verify_response: function(response) {
        if (response.body.NotLogged) {
            return false;
        }
        return true;
    },

    remove_diacritica: function(string) {
        let data_to_replace = [
            {original: 'â', replace: 'a'}, 
            {original: 'ă', replace: 'a'},
            {original: 'î', replace: 'i'},
            {original: 'ș', replace: 's'},
            {original: 'ț', replace: 't'}
        ];

        data_to_replace.forEach(data => {
            string = string.replaceAll(data.original, data.replace);
        });

        return string;
    },

    generate_slug: function(title = ''){
        let slug = this.remove_diacritica(title)
                        .toLowerCase()
                        .replace(/\s+/g, '-')
                        .replace(/&/g, '-y-')
                        .replace(/[^\w\-]+/g, '')
                        .replace(/\-\-+/g, '-');  

        if(slug.slice(-1) === '-')
            slug = slug.substring(0, slug.length - 1);
        return slug;
    },

    verify_login_and_redirect: function(vueInstance) {
        if (!this.is_logged_in()) {
            vueInstance.$router.push("/");
        }
    },
};
