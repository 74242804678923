import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
    routes: [
        { path: '/'                     , name: 'home'                  , component: () => import( './pages/Login.vue'                      ) },
        { path: '/dashboard'            , name: 'dashboard'             , component: () => import( './pages/Dashboard.vue'                  ) },
        { path: '/categorii-drepturi'   , name: 'categorii-drepturi'    , component: () => import ('./pages/categorii-drepturi/Categorii-drepturi_list.vue'     ) },
        { path: '/drepturi'             , name: 'drepturi'              , component: () => import ('./pages/drepturi/Drepturi_list.vue'                         ) },
        { path: '/grupuri-utilizatori'  , name: 'grupuri-utilizatori'   , component: () => import ('./pages/grupuri-utilizatori/Grupuri-utilizatori_list.vue'   ) },
        { path: '/utilizatori'          , name: 'utilizatori'           , component: () => import ('./pages/utilizatori/Utilizatori_list.vue'                   ) },
        { path: '/abonati_evenimente'   , name: 'abonati_evenimente'    , component: () => import( './pages/abonati_evenimente/Abonati_evenimente_list.vue'     ) },
        { path: '/top_banner', name: 'top_banner', component: () => import( './pages/top_banner/Top_banner_list.vue') },
        { path: '/categorii', name: 'categorii', component: () => import( './pages/categorii/Categorii_list.vue') },
        { path: '/evenimente', name: 'evenimente', component: () => import( './pages/evenimente/Evenimente_list.vue') },
        { path: '/mesaje_contact', name: 'mesaje_contact', component: () => import( './pages/mesaje_contact/Mesaje_contact_list.vue') },
        { path: '/newsletter_email', name: 'newsletter_email', component: () => import( './pages/newsletter_email/Newsletter_email_list.vue') },
        { path: '/pagini_statice', name: 'pagini_statice', component: () => import( './pages/pagini_statice/Pagini_statice_list.vue') },
        { path: '/parteneri', name: 'parteneri', component: () => import( './pages/parteneri/Parteneri_list.vue') },
        { path: '/right_ads', name: 'right_ads', component: () => import( './pages/right_ads/Right_ads_list.vue') },
        { path: '/right_ad_items', name: 'right_ad_items', component: () => import( './pages/right_ad_items/Right_ad_items_list.vue') },
        { path: '/slideshows', name: 'slideshows', component: () => import( './pages/slideshows/Slideshows_list.vue') },
        { path: '/articole', name: 'articole', component: () => import( './pages/articole/Articole_list.vue') },
        { path: '/abonati_thebizz', name: 'abonati_thebizz', component: () => import( './pages/abonati_thebizz/Abonati_thebizz_list.vue') },
    ]
})
